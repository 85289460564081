import { defineStore, storeToRefs } from 'pinia'
import { useGetCar } from '@autobid/ui/composables/useCar'
import { useLocalRef } from '@autobid/ui/composables/useLocalRef'
import { APP_ID_NAME_MAP } from '@autobid/ui/constants/APP_ID_NAME_MAP'
import {
  NEUTRAL_AUCTION_ID,
  PRICELIST_STATUS_MAP
} from '../consts/pricelistStatusMap'
import { useApp } from '../../ui/composables/useApp'
import { useAuction } from '../../ui/composables/useAuction'

type State = {
  app: null | string
  status: null | string
  auctionFilter: null | string
}

const AUCTION_FILTER_STAUSES = [
  'live_online_handling_fee',
  'distribution_fee'
] as const

export const useSetPricelist = (carId: number) => {
  const { state } = storeToRefs(usePricelistStore())
  const { getCar } = useGetCar()
  const car = getCar(carId)
  const { auction } = useAuction(car?.auctionId)
  const app = useApp(car?.appId as number)

  const setPricelist = () => {
    if (!car || !auction.value) {
      throw new Error('Auction or car not found')
    }
    const statuses = new Set(auction.value.activeStatusesNames)

    const status = statuses.has(NEUTRAL_AUCTION_ID)
      ? PRICELIST_STATUS_MAP.neutral_auction
      : Object.entries(PRICELIST_STATUS_MAP).find((entry) => {
          const [key] = entry
          return statuses?.has(key)
        })?.[1]

    const auctionFilter =
      AUCTION_FILTER_STAUSES.find((status) => statuses.has(status)) ?? null
    if (!status || !app.value) {
      throw new Error('Status or app not found')
    }

    state.value = {
      status,
      app: APP_ID_NAME_MAP[app.value.id as keyof typeof APP_ID_NAME_MAP],
      auctionFilter
    }
  }

  return {
    setPricelist
  }
}

export const usePricelistStore = defineStore('pricelist', () => {
  const state = useLocalRef<State>('pricelist', {
    app: null,
    status: null,
    auctionFilter: null
  })

  return { state }
})

export const NEUTRAL_AUCTION_ID = 'neutral_auction'

export const PRICELIST_STATUS_MAP = {
  surcharge_standard: 'standard',
  surgarge_low_bugdet_10_50: 'lowBudget',
  open_YUC_auction_with_seller_Autobid: 'openYuc',
  bmw_accident_auction: 'bmwAccident',
  bmw_bank_auction: 'bmwBank',
  bmw_auction_surcharge: 'bmwAuction',
  BMW_I_VOLUTION: 'bmwIvolution',
  [NEUTRAL_AUCTION_ID]: 'neutralAuction'
}
